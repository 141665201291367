'use client';

import { usePathname } from 'next/navigation';
import { Dispatch, FC, ReactNode, SetStateAction, createContext, useEffect, useState } from 'react';

type PropsContextType = {
  isHeaderDark: boolean;
  setIsSectionScrolled: Dispatch<SetStateAction<boolean>>;
};

const initialContext = {
  isHeaderDark: false,
  setIsSectionScrolled: () => {},
};

export const HeaderContext = createContext<PropsContextType>(initialContext);

export const HeaderContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const pathname = usePathname();
  const [isSectionScrolled, setIsSectionScrolled] = useState(false);

  const [isHeaderDark, setIsHeaderDark] = useState(
    (pathname.includes('/themes/') && pathname.length > 8) || pathname === '/' ? true : false
  );

  useEffect(() => {
    if ((pathname.includes('/themes/') && pathname.length > 8) || pathname === '/') {
      if (!isSectionScrolled) {
        setIsHeaderDark(true);
      } else {
        setIsHeaderDark(false);
      }
    } else {
      setIsHeaderDark(false);
    }
  }, [isSectionScrolled, pathname]);

  return (
    <>
      <HeaderContext.Provider
        value={{
          isHeaderDark,
          setIsSectionScrolled,
        }}>
        {children}
      </HeaderContext.Provider>
    </>
  );
};
